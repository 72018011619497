<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <router-link to="/" class="nav-link">
        <img class="logo" src="img/logo/logo.svg" style="max-width: 96px" />
      </router-link>
      <button class="navbar-toggler" v-on:click="toggleNav()">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link to="/over" class="nav-link">Over</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/bestuur" class="nav-link">Bestuur</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact" class="nav-link">Contact</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/downloads" class="nav-link"
              >Downloads</router-link
            >
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/contactformulier"
              ><button class="btn btn-sm btn-primary" type="button">
                Vraag offerte aan
              </button></router-link
            >
          </li>
        </ul>
      </div>
    </nav>
    <div class="overlay overlay-scale" v-bind:class="{ open: isActive }">
      <svg
        class="overlay-close"
        v-on:click="isActive = !isActive"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 31.112 31.112"
        style="enable-background: new 0 0 31.112 31.112"
        xml:space="preserve"
      >
        <polygon
          points="31.112,1.414 29.698,0 15.556,14.142 1.414,0 0,1.414 14.142,15.556 0,29.698 1.414,31.112 15.556,16.97   29.698,31.112 31.112,29.698 16.97,15.556 "
        />
      </svg>
      <nav>
        <ul>
          <li v-on:click="isActive = !isActive">
            <router-link to="/over" class="nav-link">Over</router-link>
          </li>
          <li v-on:click="isActive = !isActive">
            <router-link to="/bestuur" class="nav-link">Bestuur</router-link>
          </li>
          <li v-on:click="isActive = !isActive">
            <router-link to="/contact" class="nav-link">Contact</router-link>
          </li>
          <li v-on:click="isActive = !isActive">
            <router-link to="/downloads" class="nav-link"
              >Downloads</router-link
            >
          </li>
          <li v-on:click="isActive = !isActive">
            <router-link to="/contactformulier">
              <button class="btn btn-primary" type="button">
                Vraag offerte aan
              </button>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data: function () {
    return {
      isActive: this.isActive,
    }
  },
  methods: {
    toggleNav: function () {
      this.isActive = !this.isActive
    },
  },
}
</script>


<style lang="scss">
.logo {
  width: 400px;
}
.logo-navbar {
  width: 96px;
}
/* Overlay style */
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1;
}

/* Overlay closing cross */
.overlay .overlay-close {
  width: 48px;
  height: 48px;
  position: absolute;
  right: 48px;
  top: 48px;
  overflow: hidden;
  border: none;
  text-indent: 200%;
  color: transparent;
  outline: none;
  z-index: 100;
  fill: white;
}

/* Menu style */
.overlay nav {
  text-align: center;
  position: relative;
  top: 50%;
  height: 60%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  height: 100%;
  position: relative;
}

.overlay ul li {
  display: block;
  height: 20%;
  height: calc(100% / 5);
  min-height: 54px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.overlay ul li a {
  font-size: 54px;
  font-weight: 300;
  display: block;
  color: #fff;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.overlay ul li a:hover,
.overlay ul li a:focus {
  color: #f0f0f0;
}

/* Effects */
.overlay-scale {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.overlay-scale.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
}

img {
  box-shadow: 0px 0px 0px #000 !important;
  border: none;
}

@media screen and (max-height: 30.5em) {
  .overlay nav {
    height: 70%;
    font-size: 34px;
  }
  .overlay ul li {
    min-height: 34px;
  }
}
</style>