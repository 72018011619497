<template>
  <section
    class="page home text-center cover-container d-flex w-100 h-100 mx-auto flex-column justify-content-center"
  >
    <main role="main" class="inner cover">
      <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
        <Logo />
        <div class="product-device box-shadow d-none d-md-block"></div>
        <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
      </div>
    </main>
  </section>
</template>

<script>
import Logo from '@/components/Logo'

export default {
  name: 'Landingspagina',
  components: {
    Logo,
  },
}
</script>

<style lang="scss">
.home {
  margin-top: -56px;
}
</style>