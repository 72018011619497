<template>
  <div id="app" class="bg-dark">
    <NavBar />

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <Footer />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'

export default {
  components: {
    NavBar,
    Footer,
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

$break-small: 320px;
$break-large: 1200px;

html,
body,
#app {
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
html {
  font-size: 16px;
  font-size: 125%;
}

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.45;
}

p {
  margin-bottom: 1.25em;
}

a {
  cursor: pointer;
}

#app * {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  margin: 2em auto;
}

.middle {
  @media screen and (min-width: $break-small) {
    display: flex;
    align-items: center;
  }
}

.spacing {
  margin: 4em auto;
}

.jumbotron {
  border-radius: 0 !important;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  width: 100%;
}

.horizontal-rule {
  border-color: #fff;
  width: 70%;
  margin: 2em auto;
}

.container {
  .styled-image {
    box-shadow: 1px 1px 10px #000 !important;
    border: 2px solid white;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
