<template>
  <div>
    <div class="home-title">
      <div class="cover-border top" v-bind:class="{ animated: isActive }"></div>
      <div v-bind:class="{ animated: isActive }">
        <img class="logo" :class="size" src="img/logo/logo.svg" />
      </div>
    </div>
    <div
      class="cover-border bottom"
      v-bind:class="{ animated: isActive }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: ['size'],
  data: function () {
    return {
      isActive: false,
    }
  },
  created: function () {
    setTimeout(() => {
      this.isActive = !this.isActive
    }, 1000)
    setTimeout(() => {
      this.$router.push('/over')
    }, 3000)
  },
}
</script>


<style lang="scss">
logo-navbar {
  width: 96ppx;
}
.home-title {
  * {
    margin: 0;
  }
}
.cover-heading {
  font-size: 10em;
  opacity: 0;
  transition: 1s all ease-in-out;

  &.animated {
    opacity: 1;
  }
}

.cover-border {
  &.top,
  &.bottom {
    position: relative;
    display: block;
    content: '';
    width: 250px;
    height: 1px;
    background-color: #fff;
    margin: 0 auto;
    transition: 1s all ease-in-out;
  }

  &.top {
    left: -3000px;
  }
  &.bottom {
    left: 3000px;
  }

  &.animated {
    left: 0;
  }
}
</style>