<template>
  <div>
    <!-- Footer -->
    <footer class="page-footer font-small navbar-light bg-light pt-4">
      <!-- Footer Links -->
      <div class="container text-center text-md-left">
        <!-- Grid row -->
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-6 mt-md-0 mt-3">
            <!-- Content -->
            <router-link to="/contactformulier" class="d-block d-sm-none mobile-button">
              <button class="btn btn-primary" type="button">Vraag offerte aan</button>
            </router-link>

            <h5 class="text-uppercase">Contact</h5>
            <ul class="list-unstyled">
              <li>Coöperatie CEBS U.A.</li>
              <li>Postbus 12887</li>
              <li>1100 AW Amsterdam</li>
              <li></li>
              <li>
                <a
                  class="text-dark"
                  href="mailto:ledenadministratie@cebs.nl"
                >ledenadministratie@cebs.nl</a>
              </li>
              <li>
                <a class="text-dark" href="callto:+3126368191">020-6368191</a>
              </li>
            </ul>

            <router-link to="/contactformulier" class="d-none d-sm-block mobile-button">
              <button class="btn btn-primary" type="button">Vraag offerte aan</button>
            </router-link>
          </div>
          <!-- Grid column -->

          <hr class="clearfix w-100 d-md-none pb-3" />

          <!-- Grid column -->
          <div class="col-md-3 mb-md-0 mb-3 navbar-nav">
            <!-- Links -->
            <h5 class="text-uppercase">Links</h5>

            <ul class="list-unstyled">
              <li class="nav-item">
                <router-link class="nav-link" to="/over">Over</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/bestuur">Bestuur</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contact">Contact</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/downloads">Downloads</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contactformulier">Contactformulier</router-link>
              </li>
            </ul>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 mb-md-0 mb-3"></div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
      <!-- Footer Links -->

      <!-- Copyright -->
      <div class="footer-copyright text-center py-3 text-grey">
        © 2019 Copyright:
        <a class="text-dark" href="https://www.cebs.nl">CEBS.NL</a>
      </div>
      <!-- Copyright -->
    </footer>
    <!-- Footer -->
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
}
</script>


<style lang="scss">
.mobile-button {
  margin-bottom: 2em;
}
.nav-link {
  padding: 0;
}
</style>